// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-pro/css/all.css';

.video-wrapper{
    flex-wrap: wrap!important;
    justify-content: start!important;
}

.video {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;
    margin-bottom: 2rem;
    iframe, video {
        background: black;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.contractors-badge {
    position: absolute;
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    object-position: center;
    top: .5rem;
    left: .5rem;
}

.badge-section {
    // width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 128px;
        height: 128px;
        object-fit: contain;
        object-position: center;
    }
    p {
        text-align: center;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 2rem;
        flex-direction: row;
        justify-content: center;
        img {
            width: 64px;
            height: 64px;
            object-fit: contain;
            object-position: center;
            margin-right: 1rem;
        }
        p {
            text-align: unset;
            font-size: 12px;
        }
    }
}
